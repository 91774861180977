<template>
	<b-form class="p-2" @submit="forgotPasswordSubmit" ref="forgot" id="forgot">
		<heading class="mb-3">Forgot Password</heading>
		<div v-if="submitted" class="pt-4 pb-5">
			Thanks. Watch your inbox for your new password.
		</div>
		<div v-else>
			<p>We'll send you a new password</p>
			<b-form-group
				label="Email"
				label-for="Email"
			>
				<b-form-input id="Email" trim required placeholder="Email" v-model="Email" autofocus></b-form-input>
			</b-form-group>
			<b-form-group
				label="Username"
				label-for="Username"
				label-class="optional"
			>
				<b-form-input id="Username" trim placeholder="Username" v-model="Username"></b-form-input>
			</b-form-group>
			<b-button block type="submit" class="mt-4 mb-3">Reset Password</b-button>
		</div>
		<div class="d-flex justify-content-between">
			<a href="#" @click.prevent="$store.commit('login/login')">Back to Login</a><a href="#" @click="support">Contact Support</a>
		</div>
	</b-form>
</template>
<script>
import axios from "axios"
export default {
	name: 'ForgotPasswordForm',
	data() {
		return {
			Username: '',
			Email: '',
			submitted: false
		}
	},
	methods: {
		support() {
			this.$store.commit('login/close')
			this.$router.push('/support#contact')
		},
		forgotPasswordSubmit(e) {
			e.preventDefault()
			const {Email, Username} = this
			console.log({Email, Username})
			console.log(e, e.target, this.$refs.forgot, document.getElementById('forgot'), new FormData(this.$refs.forgot).values());
			this.submitted = true
			axios.post('/asp/ProcessLostPassword.asp', {Email, Username})
			setTimeout(() => {
				this.$store.commit('login/login') 
			}, 5000);
		},
	}
}
</script>
