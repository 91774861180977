import Vue from 'vue'

const state = () => ({
	props: {
		show: false,
		token: '',
		videoId: ''
	},
})

const mutations = {
	setProps(state, object) {
		Vue.set(state, 'props', {...object, show: true})
		console.log('setProps', state);
	},
}

const getters = {
	url: state => `https://video.wisetechglobal.com/v.ihtml/player.html?token=${state.props.token}&source=embed&photo%5fid=${state.props.videoId}`
}
export default {
	namespaced: true,
	state,
	mutations,
	getters
}