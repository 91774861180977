import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './globalComponents'
Vue.config.productionTip = false

console.log('Vue.config.devtools', Vue.config.devtools);

// ** NASTY BANDAID ALERT **
// mounting the app directly caused a flicker of empty router view 
// and adding the delay caused the prerendering to not work
// so sadly use direct mounting for build and 
// build for production
// if (!Vue.config.devtools) {
	// store.dispatch('loadCourses').then(() => {
	// 	const v = new Vue({
	// 		router,
	// 		store,
	// 		render: h => h(App),
	// 		// mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
	// 	}).$mount('#app')
	// })
// } else {
	// sadly needed to add this delay in here as was showing empty initially


	let v

	document.addEventListener('x-app-rendered', () => {
		console.log('rendered', v);
		
		const app = document.getElementById('app')
		document.body.replaceChild(v.$el, app)
			// v.$mount('#app')
		// }
		// while (app.firstChild) app.firstChild.remove();
		// app.appendChild(v.$el)
	})

	store.dispatch('loadCourses').then(() => {
		v = new Vue({
			router,
			store,
			render: h => h(App),
			// mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
		}).$mount()
		// setTimeout(, 500);
	})

// }

