<template>
	<b-form class="p-2" action="/asp/ProcessLogon.asp" ref="login" method="post">
		<heading class="mb-3">Log In</heading>
		<b-form-group label="Username" label-for="username">
			<b-form-input
				id="username"
				autofocus
				trim
				required
				name="Username"
				placeholder="Username"
			></b-form-input>
		</b-form-group>
		<b-form-group label="Password" label-for="password">
			<b-form-input
				id="password"
				trim
				type="password"
				required
				name="Password"
				placeholder="Password"
			></b-form-input>
		</b-form-group>
		<div class="text-right font-size-xs pb-2">
			<a href="#" @click.prevent="$store.commit('login/forgotPassword')">Forgot Password</a>
		</div>
		<b-form-checkbox id="rememberMe" class="pb-3" size="sm" name="RememberMe">
			Remember me (do not select on a public computer)
		</b-form-checkbox>
		<b-button block type="submit" class="mb-3">Log In</b-button>
		<a
			href="https://www.cargowise.com/"
			target="_blank"
			class="d-inline-block pt-3 pr-3 logos mb-3"
			><img src="/assets/img/cargowise-logo.svg" alt="cargowise logo"/>
		</a>
		<p class="font-size-xs text-grey mb-0">
			CargoWise One users please log on using the WiseTech Academy icon on
			<a href="https://myaccount.cargowise.com/" target="_blank">My Account</a>.
		</p>
	</b-form>
</template>

<script>
import hasSupport from "@/mixins/hasSupport";
export default {
	name: 'LoginForm',
	mixins: [hasSupport]
}
</script>