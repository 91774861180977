<template>
	<div class="wrapper p-3 bg-white position-absolute t-0 box-shadow" :class="{list}">
		<span class="icon-save float-right ml-1" :class="item.saved ? 'text-primary' : 'text-grey'"></span>
		<h3 class="mb-1">{{item.title}}</h3>
		<div class="font-size-xs text-grey">
			<p class="font-weight-bold mb-1">{{item.skill}}. {{item.time | formatTime}}</p>
			<p>
				<i class="">Linked Courses:</i>
				<span v-for="(linked, index) in item.linked" :key="index">
					<span v-if="index">, </span>
					<a :href="linked.url"> {{linked.title}}</a>
				</span >
			</p> 
			<p v-html="item.description"></p>
			<h2 class="px-3 py-2 ml-n3 text-black bg-grey-1">A${{item.price}}</h2>
			<b-button block variant="primary" class="mb-2">Buy Now</b-button>
			<a href="#">Discounted prices for CargoWise and FTA members</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CourseDetail',
	props: {
		item: Object, 
		list: Boolean,
		mouseX: Number
	},
	mounted() {
		this.list ? this.checkListX() : this.checkGridX()
		// this.$el.style[x < window.innerWidth / 2 ? 'left' : 'right'] = '50%'
	},
	methods: {
		checkGridX() {
			const x = this.$el.getBoundingClientRect().right
			const out = x - window.innerWidth + 30
			
			if (out > 0) {
			console.log(x, window.innerWidth, x - window.innerWidth);
				this.$el.style.transform = `translateX(-${out}px)`
			}
		},
		checkListX() {
			// if (thi)
			this.$el.style.left = '0px'
			// this.$el.style.left = (this.mouseX + 30) + 'px'
			// const x = this.$el.getBoundingClientRect().right

			// console.log('checklistx', this.mouseX);
		}
	}
}
</script>

<style lang="scss" scoped>
.wrapper{
	width: 455px;
	left: 50%;
	z-index: 4;
}
</style>