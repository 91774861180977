import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import video from "@/store/modules/video";
import login from "@/store/modules/login";
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		courses: [],
		view: 'grid'
	},
	mutations: {
		setCourses(state, val) {
			state.courses = val
		},
		setView(state, view) {
			state.view = view
		}
	},
	actions: {
		loadCourses({state, commit}) {
			if (state.courses.length) return
			return axios.get('/assets/featuredCourses.json').then(response => { 
				commit('setCourses', response.data)
			})
		}
	},
	modules: {
		video, login
	},
	getters: {
		courseLink: state => id => '/course/' + state.courses.find(el => el.id == id).slug
	}
})
