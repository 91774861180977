<template>
	<div id="app">
		<AboveTopBar />
		<TopBar />
		<transition name="fade" mode="out-in" @enter="onEnter" @after-enter="afterEnter">
			<router-view :key="$route.fullPath" class="mb-6" id="content" />
		</transition>
		<Footer/>
		<LoginModal/>
		<Cookies/>
	</div>
</template>

<script>
import { TopBar, Footer, LoginModal } from "@/components";
import { AboveTopBar, Cookies } from "@/components/ui";
let counter = 0
export default {
	components: {TopBar, Footer, LoginModal, AboveTopBar, Cookies},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: "Global Supply Chain Industry Courses",

		// all titles will be injected into this template
		titleTemplate: "%s | WiseTech Academy"
	},
	mounted() {
		// this.$store.commit('login/setLoggedIn')
	},
	methods: {
		onEnter () {
			if (counter++) this.$root.$emit('triggerScroll') // don't do it first time
		},
		afterEnter() {
			this.$nextTick(
				() => {
					document.dispatchEvent(new Event("x-app-rendered"))
					console.log('event dispatched!');
				}
			)
		}
	},
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
