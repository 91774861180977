import { render, staticRenderFns } from "./AboveTopBar.vue?vue&type=template&id=37701196&scoped=true&"
var script = {}
import style0 from "./AboveTopBar.vue?vue&type=style&index=0&id=37701196&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37701196",
  null
  
)

export default component.exports