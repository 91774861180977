<template>
	<b-col sm="6" xl="5" class="font-size-sm mb-4 mb-md-0 small-on-xs text-center text-sm-left">
		<img :src="'/assets/img/icons/'+item.img">
		<div class="font-weight-bold py-3">{{item.title}}</div>
		<div class="body text-left">{{item.body}}</div>
	</b-col>
</template>

<script>
export default {
	props: ['item']
}
</script>

<style lang="scss" scoped>
img{
	height: 66px;
}
.body{
	color: #1B3750;
}
</style>