import Vue from 'vue'
import VueMeta from 'vue-meta'
import { LayoutPlugin, ButtonPlugin, FormInputPlugin, InputGroupPlugin, FormGroupPlugin, BForm, BFormCheckbox, BFormSelect, BFormTextarea, CollapsePlugin } from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import inViewportDirective from 'vue-in-viewport-directive'

import { Hero, FeaturedCourses, VideoCarousel, ContactForm, SortedCourses } from '@/components'
import { CourseGrid, CourseDetail, Accordian, Logos, TrainYourTeam, Heading } from '@/components/ui'
import './utils/vueFilters'

// set defaults in first bootstrap use
Vue.use(LayoutPlugin, { BButton: { variant: 'primary' } })
Vue.use(ButtonPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormGroupPlugin)
Vue.use(CollapsePlugin)
Vue.use(VueAwesomeSwiper) 
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueReCaptcha, { 
	siteKey: '6LeU088ZAAAAAMHrdrw5ZTCaWEMXliQVnh4pXJ3m',
	loaderOptions: {
		autoHideBadge: true
	}
})
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('hero', Hero)
Vue.component('featured-courses', FeaturedCourses)
Vue.component('course', CourseGrid)
Vue.component('accordian', Accordian)
Vue.component('video-carousel', VideoCarousel)
Vue.component('contact-form', ContactForm)
Vue.component('logos', Logos)
Vue.component('course-detail', CourseDetail)
Vue.component('train-your-team', TrainYourTeam)
Vue.component('heading', Heading)
Vue.component('sorted-courses', SortedCourses)
inViewportDirective.defaults.margin = '0% 0% -10% 0%'
Vue.directive('in-viewport', inViewportDirective)

