<template>
	<b-col md="10" lg="6" class="course small-on-xs outer has-moving-arrow d-flex mb-3 mb-lg-0 flex-column flex-sm-row" @click="$router.push('/course/'+item.slug)">
		<div class="top flex-grow-1" :style="`background-image: url(${item.image})`"></div>
		<div class="bg-black text-white p-3 position-relative d-flex flex-column">
			<h5 class="font-weight-bold"><span class="icon-save float-right ml-2" :class="item.saved ? 'text-primary' : 'text-grey'"></span>{{item.title}}</h5>
			<p class="font-size-xs" v-html="item.shortDescription"></p>
			<h3 class="text-white mt-auto">${{item.price}}</h3>
			<div class="btn-xs text-white moving-arrow visible-arrow text-left bg-none d-inline">LEARN MORE</div>
		</div>
	</b-col>
</template>

<script>
export default {
	name: 'FeaturedCourse',
	props: {
		item: {
			type: Object,
			default: () => ({}),
		}
	}
}
</script>

<style lang="scss" scoped>
.price{
	background: rgba($primary, .6);
	font-size: 28px;
}
.top{
	padding-top: 35%;
}
@include media-breakpoint-up(sm){
	.bg-black{
		width: calc(50% - #{$spacer});
	}
}
</style>