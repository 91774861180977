const hasSwiper = {
	data() {
		return {
			isEnd: false,
			isBeginning: true
		}
	},
	computed: {
		mySwiper() {
			return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : {}
		}
	},
	methods: {
		onSlideChange(e) {
			this.isBeginning = e.isBeginning
			this.isEnd = e.isEnd
		}
	}
}

export default hasSwiper