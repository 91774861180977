import Vue from 'vue'

Vue.filter('formatTime', value => {
	const t = parseInt(value)
	if (window.isNaN(t)) return value
	const h = Math.floor(t / 60)
	const m = Math.floor(t % 60)
	if (h && m) return `${h}h ${m}m`
	if (h) return h + 'h'
	return m + 'm'
})