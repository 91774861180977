<template>
	<b-list-group-item class="outer position-relative d-flex align-items-center h5 mb-0 font-weight-md" @mouseenter="enter" @mouseleave="hover=false" @click="$router.push('/course/'+item.slug)">
		<span class="flex-shrink-1 pr-2">{{item.title}}</span>
		<span class="ml-auto time pr-2">{{item.time | formatTime}}</span>
		<span class="price pr-2">A${{item.price}}</span>
		<!-- <span class="save icon-save float-right ml-2" :class="item.saved ? 'text-primary' : 'text-grey'"></span> -->
		<transition name="fade">
			<course-detail class="course d-none d-lg-block" :item="item" :mouseX="mouseX" v-if="hover" list/>
		</transition>
	</b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'
export default {
	name: 'CourseList',
	props: ['item'],
	components: {
		'b-list-group-item': BListGroupItem,
	},
	data() {
		return {
			hover: false,
			mouseX: 0
		}
	},
	methods: {
		enter(e) {
			console.log(e);
			
			this.hover = true
			this.mouseX = e.offsetX
		},
	}
}
</script>

<style lang="scss" scoped>
// .top{
// 	padding-bottom: 47.62%;
// }
.time, .price{
	width: 110px;
}

.outer{
	span {
		flex-shrink: 0;
	}
	&:hover{
		z-index: 10;
		.title {
			color: $primary;
		}
	}
}
</style>