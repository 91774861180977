<template>
	<div class="outer course small-on-xs position-relative mb-3 mb-lg-3 mx-auto mx-sm-0 h-100" @mouseenter="hover=true" @mouseleave="hover=false" @click="$router.push('/course/'+item.slug)">
		<div class="wrapper d-flex flex-column h-100 cursor-pointer">
			<div class="top position-relative">
				<span class="time position-absolute b-0 r-0 p-2">{{item.time | formatTime}} </span>
				<img :src="item.image" alt="" :srcset="`${item.image}, ${item.image_lg} 1.5x`" class="w-100">
			</div>
			<div class="bottom bg-white position-relative flex-grow-1 d-flex flex-column justify-content-between pb-1">
				<p class="p-2 mb-1 title"><span class="icon-save float-right ml-2" :class="item.saved ? 'text-primary' : 'text-grey'"></span>{{item.title}} </p>
				<span class="bg-light p-1 pl-2 pr-3 h3 align-self-start">A${{item.price}}</span>
			</div>
		</div>
		<transition name="fade">
			<course-detail class="course d-none d-lg-block" :item="item" v-if="hover"/>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'CourseGrid',
	props: ['item'],
	data() {
		return {
			hover: false
		}
	}
}
</script>

<style lang="scss" scoped>
// .top{
// 	padding-bottom: 47.62%;
// }


.outer{
	&:hover{
		z-index: 10;
		.title {
			color: $primary;
		}
	}
}
</style>