<template>
	<div class="bg-primary text-white position-relative ox-h">
		<div v-if="image" class="image absolute-right w-50" :style="`background-image:url(/assets/img/hero/${image})`"></div>
		<b-container>
			<b-row class="justify-content-start">
				<b-col lg="6" class="content d-flex align-items-center py-4" :class="{large:image && !small}">
					<div>
						<h1 v-html="title" v-if="title"/>
						<h3 class="text-grey-2 pr-md-3" v-html="subtitle" v-if="subtitle" />
						<slot/>  
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'Hero',
	props: {
		image: String, 
		title: String, 
		subtitle: String, 
		isTraining: Boolean,
		small: Boolean
	}
}
</script>
<style lang="scss" scoped>
.content{
	min-height: 260px;
	&.large{
		min-height: 30vw;
		max-height: 525px;
	}
}
.image{
	background-position: center;
	background-size: cover;
}
// .training{
// 	position: static;
// 	> div {
// 		z-index: 2;
// 	}
// 	span{
// 		background: rgba(white, .5);
// 		color: $dark;
// 	}
// 	.logo{
// 		width: 90px;
// 	}
// 	&::after{
// 		position: absolute;
// 		right: 0;
// 		top: 0;
// 		bottom: 0;
// 		left: 70%;
// 		content: '';
// 		background: $teal;
// 		@include media-breakpoint-down(md){
// 			left: 0;
// 			top:50%;
// 		}
// 	}
// }
@include media-breakpoint-up(lg){
.career-path{
	.content{
		align-items: flex-start !important;
		padding-top: 80px !important;
	}
}
}

	@include media-breakpoint-down(lg){
		h1{
			font-size: $h3-font-size;
		}
		h3{
			font-size: $h4-font-size;
		}
	}

	@include media-breakpoint-down(md){
		.image{
			width: 100% !important;
			min-height: 235px;
			position: relative;
			max-height: 300px;
			height: 30vw;
		}
		.content.large{
			height: auto;
		}
	}
	@include media-breakpoint-down(sm){
		.btn{
			width: 100%;
		}
	}
</style>