<template>
	<div class="d-none d-xl-flex align-items-center flex-grow-1">
		<b-button class="d-flex align-items-center px-3 mr-3 icon-arrow-v" :class="{isOpen}" @click="isOpen = !isOpen">Explore </b-button>
		<search class="mr-3 fw d-block d-xxl-none" collapse/>
		<search class="mr-3 fw d-none d-xxl-block"/>
		<navlist class="d-flex ml-auto flex-shrink-0" classes="py-4 px-2 mx-xl-1 mx-xxl-2 flex-shrink-0"/>
		<b-button v-if="$store.state.login.isLoggedIn" variant="outline-primary" class="d-flex align-items-center ml-2" href="/asp/ProcessBanner.asp?log_out=true">Log Out</b-button>
		<b-button v-else variant="outline-primary" class="d-flex align-items-center mr-3 ml-2" @click="$store.commit('login/login')">Log In</b-button>
		<b-button v-if="!$store.state.login.isLoggedIn" class="d-flex align-items-center" @click="$store.commit('login/join')">Join Free</b-button>
		<languages/>
		<transition name="fade">
			<explore v-if="isOpen" class="explore" @mouseleave.native="isOpen = false" @click.native="clickExplore"/>
		</transition>
	</div>
</template>

<script>
import Search from "./Search";
import Nav from "./Nav";
import LanguageDropdown from "./LanguageDropdown";
import { Explore } from "../";
export default {
	name: 'DesktopMenu',
	components: {
		search: Search, 
		explore: Explore,
		navlist: Nav,
		languages: LanguageDropdown
	},
	data() {
		return {
			isOpen: false
		}
	},
	methods: {
		clickExplore(e) {
			if (e.target.href) this.isOpen = false
		}
	}
}
</script> 

<style lang="scss" scoped>

.btn {
	height: $nav-el-height;
	flex: 0 0 auto;
	white-space: nowrap;
	&:after{
		margin-left: 12px;
	}
	&.isOpen:after{
		transform: scaleY(-1) translateY(2px);
	}
}
</style>