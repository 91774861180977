import { JOIN, FORGOT, LOGIN } from "../const/loginStates"

const state = () => ({
	isOpen: false,
	isLoggedIn: false,
	state: ''
})

const mutations = {
	login(state) {
		state.state = LOGIN
		state.isOpen = true
	},
	forgotPassword(state) {
		state.state = FORGOT
		state.isOpen = true
	},
	join(state) {
		state.state = JOIN
		state.isOpen = true
	},
	close(state) {
		state.isOpen = false
	},
	setLoggedIn(state, val = true) {
		state.isLoggedIn = val
	}
}

export default {
	namespaced: true,
	state,
	mutations,
}