<template>
	<b-form @submit.prevent="onSubmit" :class="{collapse, isCollapsed:addCollapsedClass}">
		<b-input-group class="search">
			<b-form-input class="h-100 border-primary" placeholder="Search all courses" ref="search"></b-form-input>
			<b-input-group-append>
				<b-button variant="primary" type="submit" class="icon-search py-1 px-3 border-0"/>
			</b-input-group-append>
		</b-input-group>
	</b-form>
</template>

<script>
export default {
	name: 'Search',
	props: {
		collapse: {
			type: Boolean
		}
	},
	data() {
		return {
			isCollapsed: true
		}
	},
	computed: {
		addCollapsedClass() {
			return this.collapse && this.isCollapsed
		}
	},
	methods: {
		onSubmit() {
			const val = this.$refs.search.$el.value
			if (this.isCollapsed && this.collapse) {
				this.isCollapsed = false
				this.$refs.search.focus()
			} else if (this.collapse && !val) this.isCollapsed = true
			else if (val) {
				this.$router.push('/search-results?search=' + val)
				this.$refs.search.$el.value = ''
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.search{
	flex-wrap: nowrap;
	height: $nav-el-height;
}
.collapse{
	position: absolute;
	transform: translateX(120px);
	width: 290px;
	overflow: hidden;
	border-left: 1px solid $primary;
	input{
		border-left:none;
	}
	.search{
		transition: transform $transition-duration ease-in-out;
	}
	&.isCollapsed{
		.search{
			transform: translateX(-240px);
		}
	}
}
input{
	font-size: $font-size-xs !important;
}
.fw{
	flex-basis: 370px;
	flex-shrink: 1;
}
.icon-search{
	font-size: $h5-font-size;
	line-height: 0;
}
.footer-search{
	max-width: 400px;
	input{
		border: none !important;
		background: rgba(white, .35);
		color: white;
	}
	.btn{
		background: white !important;
		color: $primary !important;
	}
	::placeholder{
		color: rgba(white, .75);
	}
}
</style>

