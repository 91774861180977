<template>
	<div class="dropdown nav-level-1 language-selector flex-shrink-0" 
		:class="isOpen? 'open' : 'closed'" 
		@mouseenter.stop="setOpen(true, $event)"
		@mouseleave.stop="setOpen(false, $event)"
	>
		<a
			id="language-dropdown-button"
			href="#"
			class="nav-link px-2 py-3 ml-xxl-3 ml-xl-2 py-xl-4 pr-xl-0 icon-arrow-v"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			@click.stop="setOpen(!isOpen, $event)"
			
		>
			<span class="language-dropdown-short-name">{{active.short}}</span>
			<span class="language-dropdown-long-name">{{active.long}}</span>
		</a>
		<div
			class="dropdown-menu dropdown-menu-right"
			aria-labelledby="language-dropdown-button"
		>
			<a class="dropdown-item" v-for="(item,index) in filteredLangs"  :lang="item.lang" :href="getPath(item)" :key="index">{{item.long}}</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LanguageDropdown',
	data() {
		return {
			activeIndex: 0,
			isOpen: false,
			debouncer: 0
		}
	},
	computed: {
		langs() {
			return [
				{lang: "en-us", long: 'English', short: 'en', path: ''},
				{lang: "zh-cn", long: '简体中文', short: '简体中文'},
				{lang: "zh-hant", long: '繁體中文', short: '繁體中文'},
				{lang: "fr-fr", long: 'Français', short: 'fr'},
				{lang: "de-de", long: 'Deutsch', short: 'de'},
				{lang: "it-it", long: 'Italiano', short: 'it'},
				{lang: "ja-jp", long: '日本語', short: 'ja'},
				{lang: "pt-br", long: 'Português', short: 'pt'},
				{lang: "es-es", long: 'Español', short: 'es'},
			]
		},
		active() {
			return this.langs[this.activeIndex]
		},
		filteredLangs() {
			return this.langs.filter((el, i) => i != this.activeIndex)
		}
	},
	methods: {
		getPath(item) {
			const base = item.path ? item.path : `/${item.lang}`
			return base + this.$route.path
		},
		setOpen(val = true, e) {
			const now = Date.now()
			if (this.debouncer >= (now - 50)) return
			console.log('setOpen', val, e, now - this.debouncer);
			
			this.debouncer = now
			this.isOpen = val
		}
	}
}
</script>

<style lang="scss">
#language-dropdown-button{
	text-align: center;
	&:after{
		margin-left: 6px;
		display: inline-block;
	}
}
.language-selector{
	&.dropdown:hover .dropdown-menu {
		display: none;
	}	
	.dropdown-menu {
		width: auto;
		background: white;
		border: none;
		width: 100%;
		color: #fff;
		margin: 0;
		padding: 5px;

	}
	&.open{
		.icon-arrow-v:after{
			transform: scaleY(-1) translateY(2px);
		}
		.dropdown-menu{
			display: block !important;
		}
	}
	&.closed{
		.icon-arrow-v:after{
			transform: none
		}
		.dropdown-menu{
			display: none;
		}
	} 
	.dropdown-item{
		text-align: center;
		&:hover{
			background: none;
			color: $primary;
		}
	}
	.dropdown-toggle {
		font-size: 13px;
	}

	.language-dropdown-short-name {
		display: none;
	}

	@include media-breakpoint-up(sm){
		&:empty {
			display: none;
		}
		#language-dropdown-button, .dropdown-item{
			text-align: left;
		}
		.dropdown-toggle {
			font-size: 16px;
		}

		.language-dropdown-short-name {
			text-transform: uppercase;
			display: inline;
		}

		.language-dropdown-long-name {
			display: none;
		}
	}
}

</style>