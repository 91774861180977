<template>
	<div>
		<b-container>
			<b-row>
				<b-col>
					<heading small><slot/></heading>
				</b-col>
			</b-row>
			<b-row class="mb-3" ref="contents">
				<b-col class="d-flex align-items-center flex-wrap flex-md-nowrap">
					<span class="font-size-xs nowrap mr-2 flex-shrink-0">Filter by</span>
					<div class="d-flex align-items-center my-2 flex-grow-1">
						<b-form-select v-model="level" size="sm" :options="levels" class="mr-2">
						</b-form-select>
						<b-form-select v-model="price" size="sm" :options="prices" class="mr-2">
						</b-form-select>
						<b-form-select v-model="duration" size="sm" :options="durations">
						</b-form-select>
					</div>
					<div class="d-flex align-items-center ml-auto">
						<span class="font-size-xs nowrap mr-2 flex-shrink-0 pl-2">Sort by</span>
						<b-form-select v-model="sort" size="sm" :options="sorts" class="sort">
						</b-form-select>
					</div>
					<div class="view-toggle-wrapper position-absolute d-none d-lg-block">
						<a class="icon-view-grid ml-1 view-toggle" :class="{active:!viewList}" @click.prevent="$store.commit('setView', 'grid')" href="#"></a>
						<a class="icon-view-list ml-1 view-toggle" :class="{active:viewList}" @click.prevent="$store.commit('setView', 'list')" href="#"></a>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<div class="bg-light py-5 mb-6">
			<b-container>
				<b-row v-if="viewList">
					<b-col class="d-none d-lg-flex">
						<b-list-group class="w-100">
							<course-list v-for="(item, index) in trimmedCourses" :key="index" :item="item"></course-list>
						</b-list-group>
					</b-col>
					<!-- show grid < lg screens even if listView -->
					<course-grid v-for="(item, index) in trimmedCourses" :key="index" :item="item" class="col-lg-3 col-md-4 col-sm-6 d-lg-none"></course-grid>
				</b-row>
				<b-row v-else class="justify-content-sm-start">
					<course-grid v-for="(item, index) in trimmedCourses" :key="index" :item="item" class="col-lg-3 col-md-4 col-sm-6"></course-grid>
				</b-row>
				<b-row v-if="!filteredCourses.length" class="justify-content-sm-start">
					<b-col><p>No Results.</p></b-col>
				</b-row>
				
				<b-row v-if="filteredCourses.length > perPage" class="mt-5">
					<b-col>
						<b-pagination
							v-model="currentPage"
							:total-rows="filteredCourses.length"
							:per-page="perPage"
							align="center"
							hide-goto-end-buttons
							@change="onPaginationChange"
						>

						</b-pagination>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
import { BFormSelect, BPagination, BListGroup } from "bootstrap-vue";
import { CourseGrid, CourseList } from "./ui";
export default {
	name: 'SortedCourses',
	props: ['search'],
	components: {
		'b-form-select': BFormSelect,
		'b-pagination': BPagination,
		'b-list-group': BListGroup,
		'course-grid': CourseGrid,
		'course-list': CourseList
	},
	data() {
		return {
			price: null,
			level: null,
			duration: null,
			sort: 'date',
			perPage: 24,
			currentPage: 1,
		}
	},
	computed: {
		viewList() {
			return this.$store.state.view == 'list'
		},
		trimmedCourses() {
			return this.filteredCourses.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			)
		},
		filteredCourses() {
			let c = [...this.courses]
			c = this.filterSearch(c)
			c = this.filterLevel(c)
			c = this.filterPrice(c)
			c = this.filterDuration(c)
			return this.sortArray(c)
		},
		courses() {
			return this.$store.state.courses
		},
		levels() {
			const o = this.getDistinct('skill')
			return [
				{value: null, text: 'All Levels'}
			].concat(o)
		},
		prices() {
			return [
				{value: null, text: 'All Prices'},
				{value: 100, text: 'Under $100'},
				{value: 200, text: 'Under $200'},
			]
		},
		durations() {
			return [
				{value: null, text: 'All Durations'},
				{value: 60, text: '< 1 hours'},
				{value: 300, text: '< 5 hours'},
			]
		},
		sorts() {
			return [
				{value: 'date', text: 'Newest'},
				{value: 'price', text: 'Price'},
				{value: 'title', text: 'Alphabetical'},
				{value: 'time', text: 'Duration'},
			]
		}
	},
	methods: {
		getDistinct(prop) {
			return this.courses
				.map(item => item[prop])
				.filter((value, index, self) => self.indexOf(value) === index)
		},
		filterSearch(arr) {
			if (!this.search) return arr
			return arr.filter(el => {
				console.log('filterSearch', typeof (el.title));
				
				return el.title.toLowerCase().includes(this.search.toLowerCase())
			})
		},
		filterLevel(arr) {
			if (!this.level) return arr
			return arr.filter(el => el.skill == this.level)
		},
		filterPrice(arr) {
			if (!this.price) return arr
			return arr.filter(el => parseInt(el.price) < this.price)
		},
		filterDuration(arr) {
			if (!this.duration) return arr
			return arr.filter(el => parseInt(el.time) < this.duration)
		},
		sortArray(arr) {
			const s = this.sort

			if (s == 'title') {
				return arr.sort((a, b) => {
					return a.title.localeCompare(b.title)
				})
			}
			return arr.sort((a, b) => {
				return a[s] - b[s]
			})
		},
		onPaginationChange() {
			this.$refs.contents.scrollIntoView({behavior: "smooth"})
			// window.scroll({top: 0, left: 0, behavior: 'smooth' }
		}
	},
	watch: {
		filteredCourses() {
			this.currentPage = 1
		}
	}
}
</script>

<style lang="scss">
select.custom-select-sm{
	max-width: 185px;
	padding-right: 1.3rem;
	// background-image: url('/assets/img/icons/select-arrow.svg');
	// background-position: center right 8px;
	&.sort{
		max-width: 120px;
	}
}
.view-toggle-wrapper{
	right: $spacer;
	bottom: -53px;
}
.view-toggle{
	font-size: $font-size-xs;
	color: $grey-2;
	text-decoration: none !important;
	&.active{
		color:$primary;
	}
}
</style>