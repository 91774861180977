<template>
	<div class="outer has-moving-arrow mx-auto">
		<div class="top position-relative"><img :src="item.img" alt="" class="w-100"><img src="/assets/img/icons/play.svg" class="absolute-center play-icon"></div>
		<h4 class="text-dark mt-2 font-weight-bold">{{item.title}}</h4>
		<p class="font-size-sm" v-if="item.body">"{{item.body}}"</p>
		<span class="moving-arrow visible-arrow btn-xs text-primary">WATCH NOW</span>
	</div>
</template>

<script>
export default {
	name: 'Video',
	props: ['item']
}
</script>

<style lang="scss" scoped>
.outer:hover{
	h4, p{
		color: $primary;
	}
}
</style>