<template>
	<b-col cols="10" sm="6" md="3" class="mb-3 mb-md-0">
		<div class="info rounded-lg border-teal p-3 h-100">
			<svg width="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 257 75">
				<text>{{title}}</text>
			</svg>
			<!-- <h3 class="text-teal">US $10 tril</h3> -->
			<p class="font-size-xs">{{text}}</p>
		</div>
	</b-col>
</template>

<script>
export default {
	name: 'InfoBox',
	props: ['title', 'text']
}
</script>

<style lang="scss" scoped>
.info{
	border-style: solid;
}
svg{
	max-width: 300px;
	text{
		transform: translate(0px, 50px);
		fill: $teal;
		width: 100%;
		font-size: 3.175rem;
		font-weight: 700;
	}
}
</style>