<template>
	<div>
		<div v-for="(item, index) in items" :key="index" class="accordian-item border-bottom">
			<a v-b-toggle="getId(index)" class="d-block font-weight-bold text-dark py-2 cursor-pointer icon-arrowdown position-relative">{{item.title}}</a>
			<b-collapse :id="getId(index)" :accordion="group" class="mb-3" v-html="item.content"></b-collapse>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Accordian',
	props: ['items', 'id', 'group'],
	data() {
		return { 

		}
	},
	methods: {
		getId(index) {
			return `accordian-${this.id}-${index}`
		}
	}
}
</script>

<style lang="scss" scoped>
.accordian-item{
	margin-top: -1px;
	&:first-child{
		border-top: 1px solid $border-color;
	}
}
a.icon-arrowdown{
	padding-right: 30px;
	line-height: 1.3em;
	&:before {
		position: absolute;
		right: 10px;
		top: 10px;
		transform: rotate(180deg);
		transition: transform $transition-duration ease
	}
	&.not-collapsed:before{
		transform: none;
	}
	&:hover{
		text-decoration: none;
	}
}
</style>