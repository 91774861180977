<template>
	<div class="bg-dark text-white pt-6 pb-7">
		<b-container>
			<b-row>
				<b-col xl="6" class="pb-5">
					<h2>WiseTechAcademy</h2>
					<p class="py-4">WiseTech Academy's online courses help improve the skills, knowledge and productive capacity of professionals working in the sector.</p>
					<search class="footer-search fw"/> 
				</b-col>
				<b-col xl="3" offset-xl="1" md="7" class="pb-4">
					<div class="font-weight-bold text-lilac pb-3">Courses</div>
					<router-link to="/international-trade" class="d-block text-white">Industry courses and accreditations</router-link>
					<router-link to="/compliance" class="d-block text-white">Corporate compliance courses</router-link>
					<router-link to="/training" class="d-block text-white">Nationally recognised training</router-link>
					<div class="font-weight-bold text-lilac pb-3 pt-4">Support</div>
					<router-link to="/support#contact" class="d-block text-white">Contact Us</router-link>
					<router-link to="/support#FAQs" class="d-block text-white">FAQs</router-link>
				</b-col>
				<b-col xl="2" md="5">
					<div class="font-weight-bold text-lilac pb-3">About Us</div>
					<router-link to="/about" class="d-block text-white">Who are we</router-link>
					<router-link to="/about#mission" class="d-block text-white">Our Mission</router-link>
					<router-link to="/about#story" class="d-block text-white">Our Story</router-link>
					<div class="font-weight-bold text-lilac pb-3 pt-4">Careers</div>
					<router-link to="/career-path#insights" class="d-block text-white">Industry insights</router-link>
					<router-link to="/career-path#stories" class="d-block text-white">Stories</router-link>
					<router-link to="/career-path#jobs" class="d-block text-white">Jobs</router-link>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { Search } from "./ui";
export default {
	name: 'Footer',
	components: {
		search: Search
	}
}
</script>
