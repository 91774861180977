<template>
	<b-modal
		size="lg"
		centered
		hide-header
		hide-footer
		v-model="$store.state.video.props.show"
	>
		<b-embed
			aspect="16by9"
			:src="$store.getters['video/url']"
			allowfullscreen
		>
		</b-embed>
	</b-modal>
</template>

<script>

import { BModal, BEmbed } from 'bootstrap-vue'
export default {
	name: 'VideoPlayer',
	components: {
		'b-modal': BModal,
		'b-embed': BEmbed
	},
	watch: {
		'$store.state.video.props.show'(val) {
			console.log(val);
		}
	}
}
</script>