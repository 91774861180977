<template>
	<div class="d-flex d-xl-none align-items-center justify-content-end w-100"  :class="{'menu-active':menuActive, 'search-active':searchActive}">
		<languages class="d-none d-sm-block"/>
		<div class="icon-search text-primary h3 mb-0 py-3 mr-1 px-2 cursor-pointer" @click="onSearchClick"/>
		<div class="hamburger py-3 pl-2 cursor-pointer" @click="toggleMenu" :class="{active:isOpen}"><div/></div>
		<b-container fluid class="menu main-menu position-absolute t-100 l-0 r-0 bg-white">
			<b-row>
				<b-col>
					<b-button v-if="$store.state.login.isLoggedIn" class="d-flex align-items-center w-100 my-3 justify-content-center" href="/asp/ProcessBanner.asp?log_out=true">Log Out</b-button>
					<b-button v-else class="d-flex align-items-center w-100 my-3 justify-content-center" @click="$store.commit('login/login')">Log In</b-button>
					<b-button v-if="!$store.state.login.isLoggedIn" class="d-flex align-items-center w-100 my-3 justify-content-center" @click="$store.commit('login/join')">Join Free</b-button>
					<languages class="d-sm-none"/>
					<navlist classes="d-block w-100 h4 py-3 border-bottom"/>
				</b-col>
			</b-row>
		</b-container>
		<b-container fluid class="menu search-menu position-absolute t-100 l-0 r-0 bg-white">
			<b-col>
				<b-row>
					<search class="h3 w-100 my-2"/>
					<b-button variant="outline-primary" class="w-100 my-2" to="/international-trade">International Trade Courses</b-button>
					<b-button variant="outline-primary" class="w-100 my-2" to="/compliance">Corporate Compliance Courses</b-button>
					<b-button variant="outline-primary" class="w-100 my-2" to="/training">Accredited Training</b-button>
				</b-row>
			</b-col>
		</b-container>
	</div>
</template>

<script>
import Search from "./Search";
import Nav from "./Nav";
import LanguageDropdown from "./LanguageDropdown";
export default {
	name: 'MobileMenu',
	components: {
		navlist: Nav,
		search: Search,
		languages: LanguageDropdown
	},
	data() {
		return {
			searchActive: false,
			isOpen: false
		}
	},
	computed: {
		menuActive() {
			return this.isOpen && !this.searchActive
		},
	},
	methods: {
		toggleMenu() {
			this.isOpen ? this.close() : this.isOpen = true
		},
		close() {
			this.isOpen = false
			this.searchActive = false
		},
		onSearchClick() {
			this.searchActive = true
			this.isOpen = true
			setTimeout(() => {
				const s = document.querySelector('.search input')
				s.focus()
				s.select()
			}, 350);
		}
	},
	watch: {
		$route (to, from) {
			this.close();
		}
	} 
}
</script>

<style lang="scss" scoped>
.icon-search{
	line-height: 0;
	transition: all $transition-fast ease;
	&:hover{
		color: #000 !important;
	}
}
.search{
	height: 3.125rem;
}
.menu {
	top: 3.3rem;
	opacity: 0;
	pointer-events: none;
	transition: opacity $transition-duration ease .15s;
	&:before{
		position: fixed;
		top: 4.8rem;
		left: 0;
		right: 0;
		bottom: -60px;
		z-index: -1;
		content: ' ';
		background: white;
		backface-visibility: hidden;
	}
}
.menu-active .main-menu, .search-active .search-menu{
	pointer-events: all;
	opacity: 1;
	transition-delay: 0s;
}
.search-active .icon-search{
	opacity: 0;
	transform: translateX(2em);
	pointer-events: none;
}
.btn{
	font-size: $h5-font-size;
}
</style>