<template>
	<div class="swiper-outer">
		<div id="btn-vid-prev" class="swiper-button-prev" @click="mySwiper.slidePrev()" :class="{disabled:isBeginning}" ></div>
		<b-container>
			<b-row class="position-relative">
				<swiper :options="options" ref="mySwiper" :auto-update="true" @slideChange="onSlideChange">
					<swiper-slide v-for="(item, index) in items" 
							:key="index" class="col-sm-6 col-lg-4">
						<video-widget 
							:item="item" 
							@click.native="onClick(item)"
						
						/>
						<!-- <span class="position-absolute l-100 swiper-"><img src="" alt=""></span>
						<span class="position-absolute r-100"><img src="" alt=""></span> -->
		
					</swiper-slide>
				</swiper>
				<video-player />
			</b-row>
		</b-container>
		<div id="btn-vid-next" class="swiper-button-next" @click="mySwiper.slideNext()" :class="{disabled:isEnd}"></div>
	</div>
</template>

<script>
import { VideoWidget, VideoPlayer } from '@/components/ui'
import hasSwiper from "@/mixins/hasSwiper"

export default {
	props: {
		trim: {
			type: Boolean
		}
	},
	data() {
		return {
			options: {
				speed: 500,
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 0,
				// loop: true,
				breakpoints: {
					576: {
						slidesPerView: 2,
						slidesPerGroup: 2,
					},
					992: {
						slidesPerView: 3,
						slidesPerGroup: 3,
					},
				},
				navigation: {
					nextEl: '#btn-vid-next',
					prevEl: '#btn-vid-prev'
				}
			},
			items: [
				{img: '/assets/img/video/Aimee.jpg', title: 'Aimee', body: 'This is a really great career where we really do make a difference to people’s lives.', token: 'cf371ff0f9cab3810df7520b32e9502e', videoId: '64293939'},
				{img: '/assets/img/video/Chris.jpg', title: 'Chris', body: 'The industry allows you to come up with ideas and solutions. It’s fast paced and dynamic with the excitement and the buzz that you get out of it.', token: 'd5ba2e47b5eadb11493376ed56c58d68', videoId: '64293944'},
				{img: '/assets/img/video/Lauren.jpg', title: 'Lauren', body: 'In this business, I have never been bored. I get to see to parts of the world I never thought I would see and make friendships from all around the world.', token: 'b91e4c4c1214351c8a04beffa1ff4cad', videoId: '64293948'},
				{img: '/assets/img/video/Emi.jpg', title: 'Emi', body: 'In this industry, no two days are the same and I am constantly learning something. It’s a career where you can easily start at the bottom like I did and work your way up to become anything you want.', token: 'e6296386b7b4938e5642d00658f01c95', videoId: '64293947'},
				{img: '/assets/img/video/Venessa.jpg', title: 'Vanessa', body: 'Whether it is biosecurity, dangerous goods or advanced customs clearance, it is important to me in my role that we do our bit to protect the place in which we live.', token: 'a6aa2093661c01a3b0cc8a29ff5a4798', videoId: '64293949'},
			]
		}
	},
	mixins: [hasSwiper],
	components: {
		'video-widget': VideoWidget,
		'video-player': VideoPlayer,
	},
	created() {
		if (this.trim) {
			const rando = Math.floor(Math.random() * (this.items.length))
			const trimmed = this.items.splice(rando, 1)[0]
			console.log('trimmed', trimmed);
			
			this.$emit('trimmed', trimmed)
		}
	},
	methods: {
		onClick(item) {
			console.log('onClick', this.swiper);
			
			const {videoId, token} = item
			this.$store.commit('video/setProps', {videoId, token})
		},
	},
}
</script>
