<template>
	<b-form @submit.prevent="onSubmit" novalidate>
		<b-container>
			<b-row>
				<b-col :class="{'col-md-10': !full}">
					<heading>{{title}}</heading>
					<p class="text-grey"><slot/></p>
				</b-col>
			</b-row>
			<transition name="fade" mode="out-in">
				<b-row v-if="submitted" class="pt-6 pb-6">
					<div :class="[full ? 'col-md-6' : 'col-lg-5 col-md-10']">
						<h3>Thank you!</h3>
						<p>Your form has been submitted. We will get back to you as soon as possible.</p>
					</div>
				</b-row>
				<b-row v-else>
					<div :class="[full ? 'col-md-6' : 'col-lg-5 col-md-10']"> 
						<b-form-group
							label="Full Name"
							label-for="name"
						>
							<b-form-input
							id="name"
							trim
							required
							placeholder="Full Name"
							v-model="$v.name.$model"
							:state="validateState('name')"
						></b-form-input>
						</b-form-group>
						<b-form-group
							label="Work Email"
							label-for="email"
						>
							<b-form-input
							id="email"
							trim
							type="email"
							required
							placeholder="Work Email" 
							v-model="$v.email.$model"
							:state="validateState('email')"
						></b-form-input>
						</b-form-group>
						<b-form-group
							label="Phone Number"
							label-for="phone"
							label-class="optional"
						>
							<b-form-input id="phone" trim placeholder="Phone Number" v-model="phone"></b-form-input>
						</b-form-group>
						<b-form-group
							label="Work Phone Number"
							label-for="workPhone"
							label-class="optional"
							v-if="full"
						>
							<b-form-input id="workPhone" trim placeholder="Work Phone Number" v-model="workPhone"></b-form-input>
						</b-form-group>
					</div>
					<div :class="[full ? 'col-md-6' : 'col-lg-5 col-md-10']">
						<b-form-group
							label="Organisation"
							label-for="org"
							label-class="optional"
						>
							<b-form-input id="org" trim placeholder="Organisation" v-model="org"></b-form-input>
						</b-form-group>
						<b-form-group
							label="Expected Size of Your Company Membership"
							label-for="expectedSize"
							label-class="optional"
							v-if="full && !support"
						>
							<b-form-select id="expectedSize" :options="sizes" v-model="expectedSize"></b-form-select>
						</b-form-group>
						<b-form-group
							label="Your Issue"
							label-for="contactIssue"
							label-class="optional"
							v-if="support"
						>
							<b-form-select id="contactIssue" :options="supportOptions" v-model="contactIssue"></b-form-select>
						</b-form-group>
						<b-form-group
							label="Message"
							label-for="message"
							label-class="optional"
						>
							<b-form-textarea id="message" v-model="message"></b-form-textarea>
						</b-form-group>
						<b-form-checkbox id="optin" class="pb-3" v-model="optin"> I'd like to hear about WiseTech Academy news</b-form-checkbox>
						<!-- <img src="/assets/img/recaptcha.jpg" class="d-block pb-3"> -->
						<b-button type="submit" class="mb-4">Submit</b-button>
						<logos v-if="full" />
					</div>
				</b-row>
			</transition>
		</b-container>
	</b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from 'axios'

export default {
	name: 'ContactForm',
	props: {
		title: String, 
		full: Boolean,
		support: Boolean
	},
	mixins: [validationMixin],
	data() {
		return {
			name: '',
			email: '',
			workPhone: '',
			phone: '',
			org: '',
			expectedSize: '',
			contactIssue: '',
			message: '',
			optin: false,
			submitted: false
		}
	},
	computed: {
		sizes: () => [
			"1 to 10",
			"11 to 25",
			"26 to 50",
			"51 to 100",
			"More than 100"
		],
		supportOptions: () => [
			'General question',	
			'Username or password',	
			'Enrolment question',	
			'Technical issue',	
		]
	},
	methods: {
		onSubmit(e) {
			this.$v.$touch();
			console.log('$v', this.$v);

			if (this.$v.$anyError) {
				return;
			}
			this.$recaptcha('contact').then(token => {
				const {name, email, workPhone, phone, org, expectedSize, contactIssue, message, optin} = this
				console.log(token, {name, email, workPhone, phone, org, expectedSize, contactIssue, message, optin});
				axios.post('/asp/ProcessContact.asp', {Name: name, Email: email, WorkPhone: workPhone, Phone: phone, Organisation: org, EnrolmentSize: expectedSize, Issue: contactIssue, Message: message, GetNews: optin})
				this.submitted = true
			})
		},
		validateState(name) {
			const { $dirty, $error } = this.$v[name];
			return $dirty ? !$error : null;
		},
	},
	validations: {
		name: {
			required
		},
		email: {
			required, email
		}
	}
}
</script>
