<template>
	<transition name="fade">
		<b-container fluid class="fixed-bottom bg-sky text-white" v-if="show">
			<b-row>
				<b-col class="d-flex justify-content-between py-2 align-items-center">
					<span class="pl-xxl-5">This website uses cookies to ensure you get the best experience <a href="#" class="opacity-50 text-white">Learn More</a></span>
					<b-button @click="onClick" class="mr-xxl-5 flex-shrink-0 bg-white text-sky font-weight-bold fint-size-xs border-0">Got it</b-button>
				</b-col>
			</b-row>
		</b-container>
	</transition>
</template>

<script>
export default {
	name: 'Cookies',
	data() {
		return {
			show: true
		}
	},
	methods: {
		onClick() {
			window.localStorage.setItem('website.cookies', true)
			this.show = false
		}
	},
	created() {
		console.log('Cookies created', window.localStorage.getItem('website.cookies'), this.show);
		
		if (window.localStorage.getItem('website.cookies')) this.show = false
	}
}
</script>

<style lang="scss" scoped>
a:hover{
	opacity: 1;
}
span{
	line-height: 1.3;
}
</style>