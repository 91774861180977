<template>
	<b-col md="10" lg="6" class="course small-on-xs outer has-moving-arrow d-flex mb-3 mb-lg-0 flex-column flex-sm-row" @click="$router.push('/course/'+item.slug)">
		<div class="top flex-grow-1" :style="`background-image: url(${item.image})`"></div>
		<div class="bg-white p-3 position-relative d-flex flex-column">
			<h5 class="font-weight-bold"><span class="icon-save float-right ml-2" :class="item.saved ? 'text-primary' : 'text-grey'"></span>{{item.title}}</h5>
			<p class="font-size-xs" v-html="item.description"></p>
			<span class="mt-auto ml-n3 px-3 bg-light h3 mr-auto">${{item.price}}</span>
			<div class="btn-xs moving-arrow visible-arrow text-left bg-none d-inline">LEARN MORE</div>
		</div>
	</b-col>
</template>

<script>
export default {
	name: 'TrainingCourse',
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	}
}
</script>

<style lang="scss" scoped>
.price{
	background: rgba($primary, .6);
	font-size: 28px;
}
.top{
	padding-top: 45%;
}
.outer:hover{
	.moving-arrow{
		color: $primary;
	}
}
@include media-breakpoint-up(sm){
	.bg-white{
		width: calc(50% + #{$spacer});
	}
}
</style>