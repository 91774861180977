<template>
	<b-modal v-model="isOpen" hide-footer hide-header modal-class="hasLogo">
		<forgot-password-form v-if="state == FORGOT" />
		<login-form v-else-if="state == LOGIN" />
		<join-free-form v-else />
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { LoginForm, ForgotPasswordForm, JoinFreeForm } from './ui'
import { JOIN, FORGOT, LOGIN } from "@/store/const/loginStates"

export default {
	name: 'LoginModal',
	components: {
		'b-modal': BModal,
		'login-form': LoginForm,
		'forgot-password-form': ForgotPasswordForm,
		'join-free-form': JoinFreeForm
	},
	created() {
		this.JOIN = JOIN
		this.FORGOT = FORGOT
		this.LOGIN = LOGIN
	},
	computed: {
		isOpen: {
			get() {
				return this.$store.state.login.isOpen
			},
			set(val) {
				this.$store.commit('login/close')
			}
		},
		state() {
			return this.$store.state.login.state
		}
	},
	methods: {
		support() {
			this.$store.commit('login/close')
			this.$router.push('/support')
		},
		forgotPasswordSubmit(e) {
			e.preventDefault()
			const {email, username} = this
			console.log({email, username})
			console.log(e, e.target, this.$refs.forgot, document.getElementById('forgot'), new FormData(this.$refs.forgot).values());
			this.submitted = true
			setTimeout(() => {
				this.$store.commit('login/open')
				this.submitted = false
			}, 5000);
		},
	}
}
</script>

<style lang="scss">


.hasLogo .modal-content {
	margin-top: 120px;
	position: relative;
	background: #ffffff;
	border: 5px solid white;

	&:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 12px;
		margin-left: -12px;
	}

	&:before {
		position: absolute;
		content: 'WiseTech Academy';
		top: -60px;
		width: 100%;
		left: 0;
		text-align: center;
		font-size: 28px;
		color: white;
	}
}
</style>