<template>
	<div>
		<h2 v-if="!small"><slot/></h2>
		<h5 class="text-grey" v-if="sub">{{sub}}</h5>
		<h5 class="font-weight-md text-uppercase" v-if="small"><slot/></h5>
	</div>
</template>

<script>
export default {
	name: 'Heading',
	props: {
		sub: String,
		small: Boolean
	}	
}
</script>