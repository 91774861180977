<template>
	<b-form class="p-2" @submit="submit">
		<heading class="mb-3">Join Free</heading>
		<div v-if="submitted" class="pt-4 pb-5">
			Thanks for joining WiseTech Academy. Watch your inbox for your membership details.
		</div>
		<div v-else>
			<b-form-group label="First Name" label-for="firstName">
				<b-form-input
					id="firstName"
					autofocus
					trim
					required
					placeholder="First Name"
					v-model="firstName"
				></b-form-input>
			</b-form-group>
			<b-form-group label="Last Name" label-for="lastName">
				<b-form-input
					id="lastName"
					trim
					required
					placeholder="Last Name"
					v-model="lastName"
				></b-form-input>
			</b-form-group>
			<b-form-group label="Email" label-for="email">
				<b-form-input
					id="email"
					trim
					type="email"
					required
					placeholder="Email"
					v-model="email"
				></b-form-input>
			</b-form-group>
			<b-form-checkbox id="optin" class="pb-3" v-model="optin">I'd like to hear about WiseTech Academy news</b-form-checkbox>
			<b-button block type="submit" class="mb-3">Join for Free</b-button>
			<p class="font-size-xs mb-3">
				Already have an account with <a href="#" @click.prevent="$store.commit('login/login')">WiseTech Academy</a>?
			</p> 
		</div>
		<p class="font-size-xs text-right"><a href="#" @click.prevent="support">Contact Support</a></p>
	</b-form>
</template>

<script>
import hasSupport from "@/mixins/hasSupport";
export default {
	name: 'JoinFreeForm',
	mixins: [hasSupport],
	data() {
		return {
			firstName: '',
			lastName: '',
			email: '',
			optin: false,
			submitted: false
		}
	},
	methods: {
		submit() {
			const { firstName, lastName, email, optin } = this
			this.submitted = true
		}
	}
}
</script>