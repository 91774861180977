<template>
	<div>
		<router-link to="/enterprise" :class="[classes]">For Enterprise</router-link>
		<router-link to="/career-path" :class="[classes]">Career Path</router-link>
		<router-link to="/about" :class="[classes]">About</router-link>
		<router-link to="/support" :class="[classes]">Support</router-link>
		<a href="/student" :class="[classes]" v-if="$store.state.login.isLoggedIn">My Courses</a>
	</div>
</template>

<script>
export default {
	name: 'Nav',
	props: ['classes']
}
</script>

<style lang="scss" scoped>
a {
	color: black;
	white-space: nowrap;
	&:hover, &.active, &.router-link-active{
		color: $primary;
		text-decoration: none;
	}
}
</style>