<template>
	<b-container fluid class="sticky-top bg-white">
		<b-row>
			<b-col class="d-flex align-items-center px-xxl-6">
				<h2 class="mr-3 mb-0">
					<router-link to="/"><span class="text-primary">WiseTech</span><span class="text-dark">Academy</span></router-link>
				</h2>
				<desktop-menu />
				<mobile-menu />
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
// import { Search, Nav, DesktopMenu } from "@/components/ui";
import { DesktopMenu, MobileMenu } from "@/components/ui";

export default {
	name: 'TopBar',
	components: {
		'desktop-menu': DesktopMenu,
		'mobile-menu': MobileMenu
	}
}
</script>

<style lang="scss" scoped>
.col{
	// max-width: 1850px;
	position: static;
}
a:hover, a.router-link-active{
	text-decoration: none;
}
.sticky-top {
	border-top: 2px solid $blue
}
h2{
	font-size: 24px;
	flex-shrink: 0;
}
@include media-breakpoint-down(xs){
	h2{
		font-size: $h3-font-size;
	}
}
</style>