import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		// component: Home 
		component: () =>
			import(/* webpackChunkName: "Home" */ "../views/Home.vue")
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "About" */ "../views/About.vue")
	},
	{
		path: "/career-path",
		name: "CareerPath",
		component: () =>
			import(/* webpackChunkName: "CareerPath" */ "../views/CareerPath.vue")
	},
	{
		path: "/compliance",
		name: "Compliance",
		component: () =>
			import(/* webpackChunkName: "Compliance" */ "../views/Compliance.vue")
	},
	{
		path: "/enterprise",
		name: "Enterprise",
		component: () =>
			import(/* webpackChunkName: "Enterprise" */ "../views/Enterprise.vue")
	},
	{
		path: "/support",
		name: "Support",
		component: () =>
			import(/* webpackChunkName: "Support" */ "../views/Support.vue")
	},
	{
		path: "/international-trade",
		name: "InternationalTrade",
		component: () =>
			import(/* webpackChunkName: "InternationalTrade" */ "../views/InternationalTrade.vue")
	},
	{
		path: "/training",
		name: "Training",
		component: () =>
			import(/* webpackChunkName: "Training" */ "../views/Training.vue")
	},
	{
		path: '/course/:slug',
		name: 'Course',
		props: true,
		component: () =>
			import(/* webpackChunkName: "CourseDetail" */ "../views/CourseDetail.vue")

	},
	{
		path: '/search-results',
		name: 'SearchResults',
		props: true,
		component: () =>
			import(/* webpackChunkName: "SearchResults" */ "../views/SearchResults.vue")

	},
	{ 
		path: '/404', 
		name: 'NotFound',
		component: () =>
			import(/* webpackChunkName: "CourseDetail" */ "../views/NotFound.vue")

	},  
	{ path: '*', redirect: '/404' },  
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		let position

		if (savedPosition) {
			position = savedPosition
		} else if (to.hash) {
			position = {selector: to.hash, offset: {x: 0, y: 70}}
		} else {
			position = { x: 0, y: 0 }
		}
		console.log('router to hash', to, from, position);
		
		if (from.fullPath == to.fullPath) return position

		return new Promise(resolve => {		
			// wait for the out transition to complete (if necessary)
			this.app.$root.$once('triggerScroll', () => {
				// if the resolved position is falsy or an empty object,
				// will retain current scroll position.
				console.log('index triggerscroll', position)
				
				// dodgorama the resolve should work to scroll the selector intoview but it didn't 
				// if (position.selector)manuallyScroll(position)
				return resolve(position)
			})
		})
	}
});

function manuallyScroll(position) {
	const yOffset = position.offset.y 
	const element = document.querySelector(position.selector)
	const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset
	window.scrollTo({top: y})
}
export default router
