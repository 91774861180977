<template>
	<div>
		<b-container>
			<b-row>
				<b-col>
					<heading small>FEATURED COURSES</heading>
				</b-col>
			</b-row>
		</b-container>
		<div class="bg-light py-5 swiper-outer ox-h ox-lg-a">
			<div id="btn-fcs-prev" class="swiper-button-prev d-lg-none" @click="mySwiper.slidePrev()" :class="{disabled:isBeginning}" ></div>
			<b-container>
				<b-row class="justify-content-center position-relative">
					<swiper id="featured-courses" :options="options" ref="mySwiper" :auto-update="true" @slideChange="onSlideChange">
						<swiper-slide v-for="(item, index) in courses" 
								:key="index" class="col-sm-6 col-lg-3">
							<course :item="item" />
						</swiper-slide>
					</swiper>
				</b-row> 
			</b-container>
			<div id="btn-fcs-next" class="swiper-button-next d-lg-none" @click="mySwiper.slideNext()" :class="{disabled:isEnd}"></div>
		</div>
	</div>
</template>

<script>
import { CourseGrid } from './ui'
import hasSwiper from "@/mixins/hasSwiper"
export default {
	name: 'FeaturedCourses',
	components: {
		course: CourseGrid
	},
	data() {
		return {
			options: {
				speed: 500,
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 0,
				// loop: true,
				breakpoints: {
					576: {
						slidesPerView: 2,
						slidesPerGroup: 2,
					},
					992: {
						slidesPerView: 4,
						slidesPerGroup: 4,
					},
				},
				navigation: {
					nextEl: '#btn-fcs-next',
					prevEl: '#btn-fcs-prev'
				}
			},
		}
	},
	mixins: [hasSwiper],
	computed: {
		courses() {
			return this.$store.state.courses.slice(0, 4)
		}
	}
}
</script>

<style lang="scss">
@include media-breakpoint-up(lg){
	#featured-courses{
		overflow: visible;
	}
}
</style>